import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import "../twotwo.css"
const TwoTwo = props => {
  return (
    <Layout>
      <SEO title="Terms " />

      <div className="main">
        <div className="container">
          <div className="section_posting">
            <div className="mask_logo">
              {" "}
              +Add<strong>Friends</strong>
            </div>
            <div className="box">
              <h1>18 U.S.C. Section 2257 Compliance Notice</h1>
              <div className="row">
                <p>
                  All models, actors, actresses and other persons that appear in
                  any visual portrayal of actual or simulated sexually explicit
                  conduct appearing on, or otherwise contained in, this Website
                  were over the age of eighteen (18) years at the time the
                  visual image was produced. Records required for all depictions
                  of actual sexually explicit conduct by Title 18 U.S.C. 2257
                  are on file with the appropriate Records Custodian. All other
                  visual depictions displayed on this Website are exempt from
                  the provision of 18 U.S.C. 2257, 2257A and/or 28 C.F.R. 75,
                  because <br />
                  1) they do not portray conduct as specifically listed in 18
                  U.S.C section 2256 (2)(A) (i) through (iv),
                  <br /> 2) they do not portray conduct as specifically listed
                  in 18 U.S.C. section 2257A,
                  <br /> 3) they do not portray conduct listed in 18 U.S.C.
                  section 2256(2)(A)(v) produced after July 27, 2006, or <br />
                  4) are otherwise exempt because the visual depictions were
                  created prior to July 3, 1995.
                  <br /> The original records required pursuant to 18 U.S.C. §
                  2257 and 28 C.F.R. 75 for materials contained in the website
                  are kept by the appropriate Custodian of Records as follows:
                  <p className="twotwo">
                    <Image alt="2257 Location" filename={`g2g2257.png`} />
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TwoTwo
